import React from "react";
import theme from "theme";
import { Theme, Image, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Про послуги | Оренда яхт Umartislin
			</title>
			<meta name={"description"} content={"Ваш найкращий вибір для розкішних морських вражень"} />
			<meta property={"og:title"} content={"Про послуги | Оренда яхт Umartislin"} />
			<meta property={"og:description"} content={"Ваш найкращий вибір для розкішних морських вражень"} />
			<meta property={"og:image"} content={"https://umartislin.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://umartislin.com/img/035901.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://umartislin.com/img/035901.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://umartislin.com/img/035901.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://umartislin.com/img/035901.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://umartislin.com/img/035901.png"} />
			<meta name={"msapplication-TileImage"} content={"https://umartislin.com/img/035901.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 90px 0" quarkly-title="Product-4">
			<Override slot="SectionContent" align-items="center" />
			<Box
				min-width="100px"
				min-height="100px"
				display="block"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				grid-gap="0 35px"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
				margin="0px 0px 0 0px"
				lg-margin="0px 0px 0 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					align-items="center"
					md-flex-direction="column"
				>
					<Image
						src="https://umartislin.com/img/3.jpg"
						display="block"
						max-width="100%"
						margin="0px 0px 0 0px"
						height="500px"
						width="50%"
						object-fit="cover"
						sm-height="220px"
						md-width="100%"
						md-margin="0px 0px 50px 0px"
						sm-margin="0px 0px 35px 0px"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="0px 80px 0px 80px"
						width="50%"
						lg-padding="0px 50px 0px 50px"
						md-width="100%"
						sm-padding="0px 0 0px 0"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 42px/1.2 --fontFamily-sans" text-align="left" lg-font="normal 600 36px/1.2 --fontFamily-sans">
						Ознайомтеся з нашими ексклюзивними послугами оренди яхт
						</Text>
						<Text margin="0px 0px 50px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" lg-margin="0px 0px 40px 0px" sm-margin="0px 0px 30px 0px">
						Ласкаво просимо до Umartislin Yacht Charters, де ми пропонуємо широкий спектр послуг преміум-класу, призначених для покращення часу, проведеного на воді. Незалежно від того, чи шукаєте ви тихий відпочинок, авантюрну прогулянку чи незабутню подію, наші послуги створені для задоволення всіх ваших потреб. Наше прагнення до досконалості та увага до деталей гарантують, що кожна мить з нами буде надзвичайною.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="90px 0 100px 0" quarkly-title="News/Streams/Blog-1">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				align-items="end"
				grid-gap="0 80px"
				lg-grid-gap="0 50px"
				md-grid-template-columns="1fr"
			>
				<Box min-width="100px" min-height="100px" md-margin="0px 0px 50px 0px">
					<Image
						src="https://umartislin.com/img/4.jpg"
						display="block"
						width="100%"
						height="600px"
						object-fit="cover"
						border-radius="15px"
						sm-height="400px"
					/>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Box
						min-width="100px"
						min-height="100px"
						margin="0px 0px 30px 0px"
						lg-margin="0px 0px 35px 0px"
						border-width="0 0 1px 0"
						border-style="solid"
						border-color="--color-lightD2"
						padding="0px 0px 30px 0px"
					>
						<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans">
						Наш флот і зручності
						</Text>
						<Text margin="0px 0px 20px 0px" font="--base" color="#626970">
						Наш вражаючий флот яхт оснащений найкращими зручностями для забезпечення вашого комфорту та задоволення. Кожна яхта ретельно обслуговується та має розкішний інтер’єр, сучасні технології та достатньо місця для відпочинку. Від інтимних вітрильників до великих моторних яхт, у нас є ідеальне судно, яке задовольнить ваші потреби. 

						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						margin="0px 0px 30px 0px"
						lg-margin="0px 0px 35px 0px"
						border-width="0 0 1px 0"
						border-style="solid"
						border-color="--color-lightD2"
						padding="0px 0px 30px 0px"
					>
						<Text margin="0px 0px 20px 0px" font="--base" color="#626970">
						Сучасна технологія: усі наші яхти оснащені новітнім навігаційним і безпечним обладнанням, що забезпечує плавну та безпечну подорож.
						<br/>
Елегантний інтер’єр: відчуйте вершину розкоші з нашими красиво оформленими каютами, лаунжами та обідніми зонами.
						<br/>
Системи розваг: насолоджуйтесь улюбленою музикою, фільмами тощо з нашими передовими системами розваг.
						<br/>
Кухні для гурманів: на наших яхтах є повністю обладнані кухні, які ідеально підходять для приготування смачних страв у морі.
						<br/>
Комфортні спальні приміщення: відпочивайте спокійно в наших просторих і комфортних спальних приміщеннях, створених для максимального відпочинку.


						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="90px 0 100px 0" quarkly-title="News/Streams/Blog-1">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				align-items="end"
				grid-gap="0 80px"
				lg-grid-gap="0 50px"
				md-grid-template-columns="1fr"
			>
				<Box min-width="100px" min-height="100px">
					<Box
						min-width="100px"
						min-height="100px"
						margin="0px 0px 30px 0px"
						lg-margin="0px 0px 35px 0px"
						border-width="0 0 1px 0"
						border-style="solid"
						border-color="--color-lightD2"
						padding="0px 0px 30px 0px"
					>
						<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans">
						Індивідуальний чартерний досвід
						</Text>
						<Text margin="0px 0px 20px 0px" font="--base" color="#626970">
						У Umartislin Yacht Charters ми спеціалізуємося на створенні унікальних та персоналізованих вражень для наших гостей.
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						margin="0px 0px 30px 0px"
						lg-margin="0px 0px 35px 0px"
						border-width="0 0 1px 0"
						border-style="solid"
						border-color="--color-lightD2"
						padding="0px 0px 30px 0px"
					>
						<Text margin="0px 0px 20px 0px" font="--base" color="#626970">
						Одноденні екскурсії: досліджуйте навколишні води та насолоджуйтеся днем ​​сонця, моря та відпочинку.
						<br/>
 Чартери з ночівлею: розширте свою пригоду ночівлею з усіма домашніми зручностями.
						<br/>
 Довгі подорожі: вируште в багатоденну подорож, щоб досліджувати віддалені місця.
						<br/>
Статути подій: ідеально підходять для святкування особливих випадків, таких як весілля, ювілеї, дні народження або корпоративні заходи.
						<br/>
 Весілля: обмін клятвами в оточенні приголомшливої ​​краси відкритого моря.
						<br/>
 Корпоративні відпустки: організовуйте зустрічі, заходи з формування команди або розваги для клієнтів в унікальній та надихаючій обстановці.
						<br/>
 Вечірки та святкування: відсвяткуйте будь-яку подію незабутньою вечіркою на воді.
						<br/>
Пригодницькі чартери: для тих, хто шукає розваг і пригод, ми пропонуємо низку захоплюючих заходів.

						</Text>
					</Box>
				</Box>
				<Box min-width="100px" min-height="100px" md-margin="0px 0px 50px 0px">
					<Image
						src="https://umartislin.com/img/5.jpg"
						display="block"
						width="100%"
						height="600px"
						object-fit="cover"
						border-radius="15px"
						sm-height="400px"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 90px 0" quarkly-title="Product-4">
			<Override slot="SectionContent" align-items="center" />
			<Box
				min-width="100px"
				min-height="100px"
				display="block"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				grid-gap="0 35px"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
				margin="0px 0px 0 0px"
				lg-margin="0px 0px 0 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					align-items="center"
					md-flex-direction="column"
				>
					<Image
						src="https://umartislin.com/img/6.jpg"
						display="block"
						max-width="100%"
						margin="0px 0px 0 0px"
						height="500px"
						width="50%"
						object-fit="cover"
						sm-height="220px"
						md-width="100%"
						md-margin="0px 0px 50px 0px"
						sm-margin="0px 0px 35px 0px"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="0px 80px 0px 80px"
						width="50%"
						lg-padding="0px 50px 0px 50px"
						md-width="100%"
						sm-padding="0px 0 0px 0"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 42px/1.2 --fontFamily-sans" text-align="left" lg-font="normal 600 36px/1.2 --fontFamily-sans">
						Зв'яжіться з нами для отримання додаткової інформації
						</Text>
						<Text margin="0px 0px 50px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" lg-margin="0px 0px 40px 0px" sm-margin="0px 0px 30px 0px">
						І це лише початок. Зв’яжіться з нами сьогодні та ознайомтеся з усіма нашими пропозиціями. Наш привітний та досвідчений персонал готовий відповісти на будь-які запитання та допомогти вам спланувати ідеальний досвід оренди яхти. Зателефонуйте нам або напишіть нам, щоб отримати детальнішу інформацію та почати планувати свою пригоду.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});